import * as React from 'react'
import styled from 'styled-components'

const IMAGES = [
  'landscape/01.jpg',
  'landscape/02.jpg',
  'landscape/03.jpg',
  'landscape/04.jpg',
  'landscape/05.jpg',
  'landscape/06.jpg',
  'landscape/07.jpg',
  'landscape/08.jpg',
  'landscape/09.jpg',
  'landscape/10.jpg',
  'portrait/01.jpg',
  'portrait/02.jpg',
  'portrait/03.jpg',
  'portrait/04.jpg',
  'portrait/05.jpg',
  'portrait/06.jpg',
  'portrait/07.jpg',
  'portrait/08.jpg',
  'portrait/09.jpg',
  'portrait/10.jpg',
  'portrait/11.jpg',
] as const

export type ImageType = typeof IMAGES[number] | undefined

const isLandscape = () => {
  if (typeof window === 'undefined') return false
  return window.innerWidth / window.innerHeight > 2 / 3
}

export const getImages = () => {
  const prefix = isLandscape() ? 'landscape' : 'portrait'
  return IMAGES.filter((image) => image.startsWith(prefix))
}

export const getImage = (index: number) => getImages()[index]

export type StaticImageProps = {
  image: ImageType
  className?: any
  style?: React.CSSProperties
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void
}
export const StaticImageDisplay: React.FC<StaticImageProps> = ({
  image,
  className = '',
  style = {},
  onLoad,
}) => {
  return (
    <StaticImageWrapper className="gatsby-image-wrapper">
      <StaticImage
        src={`/images/${image}`}
        className={className}
        style={style}
        onLoad={onLoad}
      />
    </StaticImageWrapper>
  )
}
const StaticImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const StaticImage = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transition-delay: 500ms;
`
