import { css } from 'styled-components'

export const flexRowCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const flexColEnd = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`
