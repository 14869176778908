import { css, keyframes } from 'styled-components'

const rand = () => Math.floor(Math.random() * 100)
const randPlusMinus = (max: number = 100) => {
  const rand = Math.floor(Math.random() * max)
  return rand % 2 == 0 ? rand : -rand
}

/**
 * 要素の指定位置を指定の高さで切り取る
 * @param duration 経過時間%
 * @param offsetPct 垂直方向の位置Offset%
 * @param heightPx 切り取る高さ(px)
 * @param pctH 水平方向の切り取り範囲(%)
 */
const clipPathUpToDown = (
  duration: number,
  offsetPct: number,
  heightPx: number,
  pctH: number = 0
) => `
  ${duration}% {
    clip-path: inset(calc(${offsetPct}% - ${heightPx}px) ${pctH}% calc(${
  100 - offsetPct
}%));
  }
`

const arr50 = Array.from(new Array(50)).map((_, idx) => idx * 2)
const arr100 = Array.from(new Array(100)).map((_, idx) => idx)

/**
 * 要素のランダムな位置を指定の高さ (@function clipPathUpToDown の第2引数)で切り取る
 * コメント時点ではduration * 2 とすることで経過時間ごとに大きくなるようにしている
 */
export const clip50Frames = (
  option: {
    clipCoef?: number
    clipConst?: number
  } = {}
) => keyframes`
  ${arr50
    .map((duration) =>
      clipPathUpToDown(
        duration,
        duration * (option.clipCoef || 2),
        option.clipConst || rand()
      )
    )
    .join('')}
    ${clipPathUpToDown(50, 50, 0)}
  ${clipPathUpToDown(100, 50, 0)}
`

type transformOptions = {
  translateX?: number
  translateY?: number
  scaleX?: number
  scaleY?: number
}
const transform = (duration: number, options: transformOptions) => `
  ${duration}% {
    transform: translateX(${options.translateX || 0}%) translateY(${
  options.translateY || 0
}%) scaleX(${options.scaleX ? 1 + options.scaleX / 50 : 1}) scaleY(${
  options.scaleY ? 1 + options.scaleY / 50 : 1
});
  }
`

/**
 * 要素をランダムに上下左右方向に動かす
 */
export const transform50Frames = ({
  translateX,
  translateY,
}: transformOptions) => keyframes`
  ${arr100
    .map((duration) =>
      transform(duration, {
        translateX: translateX != null ? translateX : randPlusMinus(),
        translateY: translateY != null ? translateY : randPlusMinus(),
      })
    )
    .join('')}}
    ${transform(100, {})}
`
const loopCondition = (idx: number) => idx % 3 === 0 || idx % 7 === 0
export const scaleLooptransform50Frames = (
  translateMax: number,
  { scaleX, scaleY }: transformOptions
) => keyframes`
  ${arr50
    .map((duration, idx) => {
      const shouldLoop = loopCondition(idx)
      return transform(duration, {
        translateX: randPlusMinus(translateMax),
        translateY: randPlusMinus(translateMax),
        scaleX: scaleX != null && !shouldLoop ? scaleX : 0,
        scaleY: scaleY != null && !shouldLoop ? scaleY : 0,
      })
    })
    .join('')}}
    ${transform(100, {})}
`
export const transformFilter = (ratio: number) => keyframes`
    ${transform(1, {
      translateX: ratio,
      translateY: ratio,
      scaleX: ratio,
      scaleY: ratio,
    })}
    ${transform(95, {
      translateX: ratio,
      translateY: ratio,
      scaleX: ratio,
      scaleY: ratio,
    })}
    ${transform(100, {})}
`
export const opacityFilter = (
  opacity: number = 1,
  secondPointOpacity: number = opacity
) => keyframes`
  10% {
    opacity: ${opacity}; 
  }
  90% {
    opacity: ${secondPointOpacity}
    }; 
  }
  100% {
    opacity: 0; 
  }
`
/**
 * 画面上から下にかけてGlitchエフェクトをかける
 * @param duration アニメーション時間
 */
export const sequentialAnimation = (duration: number) => css`
  opacity: 0;
  animation: ${clip50Frames({ clipConst: 50 })} ${duration}s ease-in-out
      forwards,
    ${transform50Frames({ translateY: 0 })} ${duration}s ease-in-out forwards,
    ${opacityFilter()} ${duration}s ease-in-out forwards;
`

/**
 * 画面にランダムにGlitchエフェクトをかける
 * @param duration アニメーション時間
 */
export const randAnimation = (duration: number) => css`
  opacity: 0;
  animation: ${clip50Frames()} ${duration}s ease-in-out forwards,
    ${transform50Frames({})} ${duration}s ease-in-out forwards,
    ${opacityFilter()} ${duration}s ease-in-out forwards;
`

/**
 * 画像を縦横にずらしつつ拡大/縮小 → 元に戻すを繰り返すエフェクト
 * @param duration アニメーション時間
 */
export const scaleLoopFilterAnimation = (
  duration: number,
  translateMax: number,
  scaleRatio: number
) => css`
  opacity: 0;
  animation: ${scaleLooptransform50Frames(translateMax, {
        scaleX: scaleRatio,
        scaleY: scaleRatio,
      })}
      ${duration}s ease-in-out forwards,
    ${opacityFilter(0.25)} ${duration}s ease-in-out forwards;
`

/**
 * 画像全体に拡大/縮小 & 垂直/水平方向にずらすエフェクトをかけ続ける
 * @param duration アニメーション時間
 * @param ratio 拡大/縮小 & 垂直/水平方向にずらす比率(1 ~ 100)
 */
export const fixedFilterAnimation = (duration: number, ratio: number) => css`
  opacity: 0;
  animation: ${transformFilter(ratio)} ${duration}s ease-in-out forwards,
    ${opacityFilter(0.25, 0.25 * 0.9)} ${duration}s ease-in-out forwards;
`

export const animation1 = randAnimation(3)
