import * as React from 'react'
import { AnimatableElementProps } from 'src/context/AnimationContext'
import styled from 'styled-components'
import { GlitchImage } from './Glitch'
import { GlitchAction } from './Glitch/GlitchQueue'
import { StaticImageDisplay, StaticImageProps } from './Images'

type BackgroundImageProps = AnimatableElementProps &
  StaticImageProps & {
    bgImageOptions?: React.CSSProperties
    bgGlitchAction: GlitchAction
  }
export const BackgroundImage: React.FC<BackgroundImageProps> = ({
  image,
  className,
  id,
  bgImageOptions = {},
  bgGlitchAction,
  onLoad,
}) => (
  <BackgroundImageOverlay id="bgImageOverlay" className={className}>
    <BackgroundImageContainer
      id={id}
      className={className}
      style={bgImageOptions}
    >
      <GlitchImage bgGlitchAction={bgGlitchAction}>
        <StaticImageDisplay image={image} onLoad={onLoad} />
      </GlitchImage>
    </BackgroundImageContainer>
  </BackgroundImageOverlay>
)
const BackgroundImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const BackgroundImageContainer = styled.div`
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  .gatsby-image-wrapper {
    height: 100%;
  }
`
