import React, { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'

const colors = [
  '#b4b2b5',
  '#ef713f',
  '#6e21dc',
  '#68cf5d',
  '#c542c7',
  '#db535e',
  '#09a319',
]

/**
 * 上記のカラーパターンに沿ってエフェクトをかける
 * ref: https://good-web-design.com/javascript-css/glitch-effect
 */
export const GlitchFilterCanvas: FC<{ hidden: boolean }> = ({ hidden }) => {
  const canvasEl = useRef<HTMLCanvasElement>(null)
  useEffect(() => {
    const { current } = canvasEl
    if (current) {
      const ctx = current.getContext('2d')
      if (!ctx) return
      const glitch = () => {
        window.requestAnimationFrame(glitch)

        ctx.fillStyle = '#1a191c'
        ctx.fillRect(0, 0, innerWidth, innerHeight)

        ctx.shadowBlur = 0
        ctx.shadowColor = 'none'
        ctx.setTransform(1, 0, 0, 1, 0, 0)

        for (let i = 0; i < 500; i++) {
          ctx.fillStyle = `rgba(255, 255, 255, ${Math.random() * 0.01})`
          ctx.fillRect(
            Math.floor(Math.random() * innerWidth),
            Math.floor(Math.random() * innerHeight),
            Math.floor(Math.random() * 15) + 1,
            Math.floor(Math.random() * 15) + 1
          )

          ctx.fillStyle = `rgba(0,0,0,${Math.random() * 0.1})`
          ctx.fillRect(
            Math.floor(Math.random() * innerWidth),
            Math.floor(Math.random() * innerHeight),
            Math.floor(Math.random() * 10) + 1,
            Math.floor(Math.random() * 10) + 1
          )
        }

        ctx.fillStyle = colors[Math.floor(Math.random() * 40)]
        ctx.fillRect(
          Math.random() * innerWidth,
          Math.random() * innerHeight,
          Math.random() * innerWidth,
          Math.random() * innerHeight
        )
        ctx.setTransform(1, 0, 0, 0.8, 0.2, 0)
      }
      glitch()
    }
  }, [])

  return (
    <OverlayContainer hidden={hidden}>
      <canvas ref={canvasEl} />
    </OverlayContainer>
  )
}

const OverlayContainer = styled.div<{ hidden: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  opacity: 0.5;
  ${({ hidden }) => hidden && 'visibility: hidden;'}
  canvas {
    width: 100%;
    height: 100%;
  }
`
