import styled from 'styled-components'

export const Title = styled.div`
  color: #df2a2a;
  font-size: var(--font-size-large);
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  &.default {
    animation: recBlink 2s infinite;
    @keyframes recBlink {
      50% {
        opacity: 0;
      }
    }
  }
`
