import React, { FC, useLayoutEffect } from 'react'
import 'src/components/Modal/react-modal.css'
import { createGlobalStyle } from 'styled-components'
import { fontStyles } from './fonts'

type StyleProps = {
  bgColor?: string
}
export const GlobalStyles: FC<StyleProps> = ({ bgColor = 'black' }) => {
  useLayoutEffect(() => {
    document.body.style.setProperty('--bg-color', bgColor)
    window.addEventListener('orientationchange', () => window.location.reload())
  }, [])

  return (
    <>
      <Styles />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;700&display=swap"
        rel="stylesheet"
      />
      <script
        id="Noto Sans CJK JP"
        type="application/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
        (function(d) {
          var config = {
            kitId: 'zpd5tea',
            scriptTimeout: 3000,
            async: true
          },
          h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
        })(document);
    `,
        }}
      />
    </>
  )
}

const Styles = createGlobalStyle`
  html {
    height:100%;
  }
  body {
    --bg-color: black;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    color: #eaeaea;
    background-color: var(--bg-color);
    user-select: none;
    ${fontStyles};
    height: 100%;
    min-width: 360px;
    > div#___gatsby {
      height: 100%;
      > div {
        height:100%;
      }
    }
  }
  input {
    background-color: transparent;
    border: none;
    :focus {
      outline: none;
    }
  }
  textarea {
    background-color: transparent;
    border: none;
    :focus {
      outline: none;
    }
  }
  button {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    :focus {
      outline: none;
    }
  }
`
