import { css, FlattenSimpleInterpolation } from 'styled-components'
export const onWideTablet = (style: FlattenSimpleInterpolation) => css`
  @media screen and (max-width: 1367px) {
    ${style}
  }
`
export const onTablet = (style: FlattenSimpleInterpolation) => css`
  @media screen and (max-width: 1023px) {
    ${style}
  }
`
export const MOBILE_WIDTH = 812
export const onMobile = (style: FlattenSimpleInterpolation) => css`
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    ${style}
  }
`

export const onMobilePortrait = (style: FlattenSimpleInterpolation) => css`
  @media screen and (orientation: portrait) and (max-height: ${MOBILE_WIDTH}px) {
    ${style}
  }
`

export const onMobileLandscape = (style: FlattenSimpleInterpolation) => css`
  @media screen and (orientation: landscape) and (max-height: 767px) {
    ${style}
  }
`

export const SMALL_MOBILE_WIDTH = 374
export const onSmallMobile = (style: FlattenSimpleInterpolation) => css`
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    ${style}
  }
`
