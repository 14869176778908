import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

type MetaItem = {
  name: string
  content: string
}

type SEOProps = {
  title?: string
  description?: string
  url?: string
  keywords?: string[]
  meta?: MetaItem[]
  image?: string
}

const SupportedIconSizes = [48, 72, 96, 144, 192, 156, 384, 512]
const SEO: React.FC<SEOProps> = (props) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          url
          keywords
          image
        }
      }
    }
  `)

  const { siteMetadata } = data.site

  const {
    title,
    description,
    url,
    meta = [],
    keywords = [],
    image,
  } = siteMetadata
  const siteTitle = props.title || title
  const siteDescription = props.description || description
  const siteUrl = props.url || url
  const siteImage = props.image || image
  const siteKeywords = [...keywords, props.keywords].join(',')
  const metaData = [
    {
      name: 'canonical',
      content: siteUrl,
    },
    {
      name: 'description',
      content: siteDescription,
    },
    {
      name: 'image',
      content: siteImage,
    },
    {
      property: 'og:url',
      content: siteUrl,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:title',
      content: siteTitle,
    },
    {
      property: 'og:description',
      content: siteDescription,
    },
    {
      property: 'og:image',
      content: siteImage,
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'twitter:title',
      content: siteTitle,
    },
    {
      name: 'twitter:description',
      content: siteDescription,
    },
    {
      name: 'twitter:image',
      content: siteImage,
    },
    {
      name: 'keywords',
      content: siteKeywords,
    },
  ].concat(meta)

  const linkData = [
    {
      rel: 'shortcut icon',
      href: 'icons/ben_favicon.svg',
    },
    ...SupportedIconSizes.map((size) => ({
      rel: 'apple-touch-icon',
      href: `icons/icon-${size}x${size}png`,
    })),
  ]
  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={siteTitle}
      meta={metaData}
      link={linkData}
    />
  )
}

export { SEO }
