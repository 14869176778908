import React, { FC } from 'react'
import styled from 'styled-components'
import {
  randAnimation,
  scaleLoopFilterAnimation,
  sequentialAnimation,
} from './GlitchAnimation'
import { GlitchFilterCanvas } from './GlitchFilterCanvas'
import { GlitchAction } from './GlitchQueue'

/**
 * アニメーション全体の時間
 */
const duration = 1

type GlitchImageProps = {
  bgGlitchAction: GlitchAction
}
export const GlitchImage: FC<GlitchImageProps> = ({
  children,
  bgGlitchAction,
}) => {
  return (
    <>
      <GlitchContainer>
        {children}
        <GlitchFilterCanvas hidden={!bgGlitchAction.shouldGlitch} />
        {bgGlitchAction.shouldGlitch && (
          <>
            <GlitchWrapper className="invert">{children}</GlitchWrapper>
            <GlitchWrapper className="sepia">{children}</GlitchWrapper>
            <GlitchWrapper className="hue-rotate">{children}</GlitchWrapper>
            <GlitchWrapper className="contrast">{children}</GlitchWrapper>
          </>
        )}
      </GlitchContainer>
    </>
  )
}
const GlitchContainer = styled.div`
  height: 100%;
  overflow: hidden;
  .gatsby-image-wrapper {
    height: 100%;
  }
`

/**
 * 色調filterと範囲切り取り(clip-path), ずらし(tanslate), 拡大縮小(scale)のアニメーションを組みあわせてGlitch効果を表現している
 * https://developer.mozilla.org/ja/docs/Web/CSS/filter
 */
const GlitchWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.invert {
    filter: invert(75%);
    ${sequentialAnimation(duration)}
  }
  &.sepia {
    filter: sepia(100%);
    ${randAnimation(duration)}
  }
  &.hue-rotate {
    filter: hue-rotate(180deg);
    ${scaleLoopFilterAnimation(duration, 3, 1)}
  }
  &.contrast {
    filter: contrast(0.5);
    ${randAnimation(duration)}
  }

  overflow: hidden;
  .gatsby-image-wrapper {
    height: 100%;
  }
`
