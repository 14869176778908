import { css } from 'styled-components'
import { onMobile, onSmallMobile, onTablet } from './breakpoints'

export const fontStyles = css`
  --font-size-2xlarge: 35px;
  --font-size-xlarge: 32px;
  --font-size-large: 28px;
  --font-size-medium: 25px;
  --font-size-input: 25px;
  --font-size-input-small: 20px;
  --font-size-small: 20px;
  --font-size-xsmall: 16px;
  --font-size-2xsmall: 14px;
  ${onTablet(css`
    --font-size-2xlarge: 28px;
    --font-size-xlarge: 25px;
    --font-size-large: 20px;
    --font-size-medium: 16px;
    --font-size-input: 16px;
    --font-size-input-small: 16px;
    --font-size-small: 14px;
    --font-size-xsmall: 10px;
    --font-size-2xsmall: 8px;
  `)}
  ${onMobile(css`
    --font-size-2xlarge: 25px;
    --font-size-xlarge: 22x;
    --font-size-large: 18px;
    --font-size-medium: 16px;
    --font-size-input: 16px;
    --font-size-input-small: 16px;
    --font-size-small: 14px;
    --font-size-xsmall: 10px;
    --font-size-2xsmall: 8px;
  `)}
  ${onSmallMobile(css`
    --font-size-2xlarge: 22px;
    --font-size-xlarge: 18x;
    --font-size-large: 16px;
    --font-size-medium: 14px;
    --font-size-input: 16px;
    --font-size-input-small: 16px;
    --font-size-small: 12px;
    --font-size-xsmall: 8px;
    --font-size-2xsmall: 6px;
  `)}
`
