import { graphql, StaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import GatsbyImage, {
  GatsbyImageWithIEPolyfillProps,
} from 'gatsby-image/withIEPolyfill'
import React, { FC } from 'react'

const WORKS = [
  'bs_works_01',
  'bs_works_02',
  'bs_works_03',
  'bs_works_04',
  'bs_works_05',
  'bs_works_06',
  'bs_works_07',
  'bs_works_08',
  'bs_works_09',
  'bs_works_10',
  'bs_works_11',
  'bs_works_12',
  'bs_works_13',
  'bs_works_14',
  'bs_works_15',
  'bs_works_16',
  'bs_works_17',
  'bs_works_18',
  'bs_works_19',
  'bs_works_20',
  'bs_works_21',
  'bs_works_22',
  'bs_works_23',
  'bs_works_24',
  'bs_works_25',
  'bs_works_26',
  'bs_works_27',
] as const

export type WorkType = typeof WORKS[number]

type StaticImageFluidProps = Omit<GatsbyImageWithIEPolyfillProps, 'fluid'> & {
  image: WorkType
  onClick: (image: WorkType) => void
}
export const StaticImageFluid: React.FC<StaticImageFluidProps> = ({
  image,
  onClick,
  ...restProps
}) => {
  return (
    <StaticQuery
      query={query}
      render={(
        data: {
          [key in WorkType]: { childImageSharp: { fluid: FluidObject } }
        }
      ) => {
        const img = data[image]?.childImageSharp?.fluid as FluidObject
        if (!img) {
          return <></>
        }
        return (
          <div onClick={() => onClick(image)}>
            <GatsbyImage fluid={img} {...restProps} />
          </div>
        )
      }}
    />
  )
}
export const OriginalImage: FC<{
  image: WorkType
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void
}> = ({ image, onLoad }) => {
  return <img src={`/images/works/${image}.jpg`} onLoad={onLoad} />
}

export const OriginalImagePreLoad: FC<{
  image: WorkType
  onLoad?: (event: React.SyntheticEvent<HTMLLinkElement, Event>) => void
}> = ({ image, onLoad }) => {
  return (
    <link
      rel="preload"
      href={`/images/works/${image}.jpg`}
      as="image"
      onLoad={onLoad}
    />
  )
}

export const getWorks = () => {
  return [...WORKS]
}

const query = graphql`
  query StaticImageData {
    bs_works_01: file(relativePath: { eq: "works/bs_works_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_02: file(relativePath: { eq: "works/bs_works_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_03: file(relativePath: { eq: "works/bs_works_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_04: file(relativePath: { eq: "works/bs_works_04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_05: file(relativePath: { eq: "works/bs_works_05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_06: file(relativePath: { eq: "works/bs_works_06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_07: file(relativePath: { eq: "works/bs_works_07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_08: file(relativePath: { eq: "works/bs_works_08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_09: file(relativePath: { eq: "works/bs_works_09.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_10: file(relativePath: { eq: "works/bs_works_10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_11: file(relativePath: { eq: "works/bs_works_11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_12: file(relativePath: { eq: "works/bs_works_12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_13: file(relativePath: { eq: "works/bs_works_13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_14: file(relativePath: { eq: "works/bs_works_14.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_15: file(relativePath: { eq: "works/bs_works_15.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_16: file(relativePath: { eq: "works/bs_works_16.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_17: file(relativePath: { eq: "works/bs_works_17.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_18: file(relativePath: { eq: "works/bs_works_18.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_19: file(relativePath: { eq: "works/bs_works_19.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_20: file(relativePath: { eq: "works/bs_works_20.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_21: file(relativePath: { eq: "works/bs_works_21.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_22: file(relativePath: { eq: "works/bs_works_22.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_23: file(relativePath: { eq: "works/bs_works_23.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_24: file(relativePath: { eq: "works/bs_works_24.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_25: file(relativePath: { eq: "works/bs_works_25.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_26: file(relativePath: { eq: "works/bs_works_26.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bs_works_27: file(relativePath: { eq: "works/bs_works_27.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
