type GlitchCommand =
  | 'WAIT'
  | 'GLITCH'
  | 'TOGGLE_IMAGE'
  | 'PREPARE_IMAGE'
  | 'NEXT_IMAGE'

export class GlitchAction {
  private startTime: number = 0
  private constructor(
    readonly command: GlitchCommand,
    readonly durationSec: number,
    readonly minDurationSec: number = 0
  ) {}

  static wait = (durationSec: number) => new GlitchAction('WAIT', durationSec)
  static glitch = (durationSec: number, minDurationSec?: number) =>
    new GlitchAction('GLITCH', durationSec, minDurationSec)
  static prepareImage = (durationSec: number) =>
    new GlitchAction('PREPARE_IMAGE', durationSec)
  static toggleImage = (durationSec: number) =>
    new GlitchAction('TOGGLE_IMAGE', durationSec)
  static nextImage = (durationSec: number) =>
    new GlitchAction('NEXT_IMAGE', durationSec)

  get shouldGlitch() {
    return (
      this.command === 'GLITCH' || this.command === 'NEXT_IMAGE'
      // ||
      // this.command === 'TOGGLE_IMAGE' ||
      // this.command === 'PREPARE_IMAGE'
    )
  }
  get isGlitching() {
    return this.command === 'GLITCH'
  }
  get shouldChangeImage() {
    return this.command === 'NEXT_IMAGE' || this.command === 'TOGGLE_IMAGE'
  }
  get shouldPrepare() {
    return this.command === 'PREPARE_IMAGE'
  }
  get shouldToggle() {
    return this.command === 'TOGGLE_IMAGE'
  }
  get duration() {
    return this.durationSec * 1000
  }
  get restDuration() {
    return new Date().getTime() - this.startTime - this.duration
  }

  start = () => (this.startTime = new Date().getTime())
}

export class GlitchQueue {
  private index: number = 0
  constructor(readonly flow: GlitchAction[]) {}

  static new = (...flow: GlitchAction[]) => new GlitchQueue(flow)

  next = () => {
    if (this.index === this.flow.length - 1) return this.reset()
    this.index += 1
    return this.current
  }

  reset = () => {
    this.index = 0
    return this.current
  }

  get current() {
    const current = this.flow[this.index]
    current.start()
    return current
  }
}

/**
 * Glitchアニメーションをかけるタイミングをここで設定する
 * パラメータの数値は時間(秒単位)
 *
 * @see GlitchImage#duration で設定した時間がGlitchエフェクトの最大時間となる
 * それより短い秒数を指定すると、Glitchエフェクトは中断される
 * wait(3) → 3秒通常の画像を表示して待機
 * glitch(0.2) → 0.2秒Glitchアニメーションを適用
 * nextImage(6) → 6秒でGlitchアニメーションを適用しつつ次の画像を表示
 */
export const nextImageDuration = 0.15
export const defaultGlitchQueue = GlitchQueue.new(
  GlitchAction.wait(10),
  GlitchAction.glitch(0.075),
  GlitchAction.nextImage(0.075)
)
